<script setup>
import { ref, defineExpose } from 'vue';

const isOpen = ref(false);
const etapasPendentes = ref();
const close = () => (isOpen.value = false);

const openDialog = async (data) => {
    etapasPendentes.value = groupByEtapa(data);
    isOpen.value = true;
};

const groupByEtapa = (data) => {
    return data.reduce((acc, item) => {
        const existing = acc.find((entry) => entry.etapa === item.etapa);
        if (existing) {
            existing.pendencias = existing.pendencias.concat(item.pendencias);
        } else {
            acc.push({ ...item });
        }
        return acc;
    }, []);
};

defineExpose({
    openDialog
});
</script>
<template>
    <div>
        <Dialog v-model:visible="isOpen" :style="{ width: '70rem' }" header="Não foi possível efetivar a conclusão" @hide="close" :modal="true">
            <template #default>
                <Message severity="warn">Algumas etapas estão pendentes</Message>
                <div class="w-full">
                    <div class="flex flex-column flex-wrap" style="height: 540px">
                        <div v-for="(etapaPendente, index) in etapasPendentes" :key="index" class="col-6">
                            <Panel class="w-full">
                                <template #header>
                                    <div class="flex flex-column gap-2">
                                        <span class="font-bold">{{ etapaPendente.etapa }}</span>
                                    </div>
                                </template>
                                <template #default>
                                    <ul v-for="(pendencia, index) in etapaPendente.pendencias" :key="index">
                                        <li>{{ pendencia }}</li>
                                    </ul>
                                </template>
                            </Panel>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <Button label="Cancelar" text class="p-button-primary justify-content-end" @click="close" />
            </template>
        </Dialog>
    </div>
</template>
