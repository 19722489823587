<script setup>
import { ref, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { getClientSesmt } from '../../services/http-sesmt';
import { checkPermission } from '../../common/check-permission';
import { useAlert } from '../../composables/useAlert';

import TipoProdutoServicoEnum from '../../enums/TipoProdutoServicoEnum';
import SesmtService from '../../services/SesmtService';
import StatusGroCustomerBranchEnum from '../../enums/StatusGroCustomerBranch';
import HeaderPanel from './components/HeaderPanel';
import FiltrosUnidade from './components/FiltrosUnidade';
import DialogDetalhesProdutosContratados from './components/grid-unidades/DialogDetalhesProdutosContratados.vue';
import DialogCancelarVisitaTecnica from './components/DialogCancelarVisitaTecnica';
import DialogFalhaLiberacao from './components/DialogFalhaLiberacao';
import DialogMaisDetalhes from './components/grid-unidades/dialog-mais-detalhes/DialogMaisDetalhes.vue';
import DialogRelatoriosLpp from './components/DialogRelatoriosLpp';
import DialogSucessoEnvioRelatorioPorEmail from './components/DialogSucessoEnvioRelatorioPorEmail';
import DialogLiberarElaboracao from './components/grid-unidades/DialogLiberarElaboracao.vue';
import ProgressoStatus from './components/grid-unidades/ProgressoStatus';
import DialogEfetivarConclusao from './components/DialogEfetivarConclusao';
import DialogLiberarUnidade from './components/grid-unidades/dialog-liberar-unidade/DialogLiberarUnidade.vue';

const { alertError } = useAlert();

const router = useRouter();
const store = useStore();
const queryParams = ref({});
const menu = ref([]);
const records = ref([]);
const loading = ref(false);
const sort = ref(null);
const currentSortField = ref(null);
const currentSortOrder = ref(null);
const page = ref(1);
const recordsPerPage = ref(10);
const totalRecords = ref(0);
const totalPages = ref(0);
const customer = ref({});
const filtrosDoComponenteUnidade = ref({});
const statusCustomerBranchOptions = ref([]);
const produtoServicoOptions = ref([]);
const produtosServicosTodasUnidades = ref([]);
const unidadesColumns = [
    {
        field: 'name',
        header: 'Unidade',
        sortable: true,
        sortField: 'customer_branches.name',
        style: 'width: 200px'
    },
    {
        field: 'produtosContratados',
        header: 'Produtos contratados',
        sortable: false,
        style: 'width: 200px'
    },
    {
        field: 'statusGro',
        header: 'Status',
        sortable: true,
        sortField: 'customer_branches.status_gro',
        style: 'width: 100px'
    },
    {
        field: 'acoes',
        sortable: false,
        style: 'width: 55px; text-align: right'
    }
];

const dialogDetalhesProdutosContratados = ref();
const dialogFalhaLiberacao = ref();
const dialogSucessoEnvioRelatorioPorEmail = ref();
const dialogCancelarVisitaTecnica = ref();
const dialogMaisDetalhes = ref();
const dialogRelatoriosLpp = ref();
const dialogLiberarParaElaboracao = ref();
const dialogEfetivarConclusao = ref();
const dialogLiberarUnidade = ref();

const onPage = async (event) => {
    page.value = event.page + 1;
    recordsPerPage.value = event.rows;
    await load();
};

const onSort = async (event) => {
    currentSortField.value = event.sortField;
    currentSortOrder.value = event.sortOrder;
    const field = event.sortField
        ?.split(/(?=[A-Z])/)
        .join('_')
        .toLowerCase()
        ? `${event.sortField
              ?.split(/(?=[A-Z])/)
              .join('_')
              .toLowerCase()}`
        : '';
    const order = event.sortOrder == 1 ? `ASC` : 'DESC';
    if (field) {
        sort.value = {
            [field]: order
        };
    } else {
        sort.value = null;
    }

    await load();
};

const toggleMenu = (event, index) => menu.value?.[index].toggle(event);

const goToElaboration = (record) => {
    const elaboracaoId = record?.pgrElaboracao?.id;
    const unidadeId = record.id;
    const currentRoute = router.currentRoute;
    const basePath = currentRoute.value.path.endsWith('/') ? currentRoute.value.path : currentRoute.value.path + '/';
    router.push({ path: `${basePath}${unidadeId}/elaboracao/${elaboracaoId}` });
};

const getActionItems = (record) => [
    {
        label: 'Elaborar',
        icon: 'pi pi-file-edit',
        disabled: () => !podeElaborarPgr(record),
        command: () => goToElaboration(record)
    },
    {
        label: 'Liberar unidade',
        icon: 'pi pi-pencil',
        disabled: () => !podeLiberarVisitaTecnica(record),
        command: () => openDialogLiberarUnidade(record)
    },
    {
        label: 'Mais detalhes',
        icon: 'pi pi-info-circle',
        command: () => openDialogMaisDetalhes(record)
    },
    {
        label: 'Cancelar visita técnica',
        icon: 'pi pi-minus-circle',
        disabled: () =>
            !checkPermission('gestaosesmt_gro_unidade:cancelar') ||
            record.statusGro !== statusCustomerBranchOptions.value.find((p) => p.label === StatusGroCustomerBranchEnum.EM_VISITA_TECNICA)?.value,
        command: () => openDialogCancelarVisitaTecnica(record)
    },
    {
        label: 'Geração de relatórios',
        icon: 'pi pi-file',
        command: () => openDialogGeracaoRelatorios(record)
    },
    {
        label: 'Efetivar conclusão',
        icon: 'pi pi-file',
        disabled: () => !checkPermission('gestaosesmt_gro_unidade:concluir'),
        command: () => openDialogEfetivarConclusao(record)
    }
];

const openDialogProdutosContratados = (data, semProduto) => {
    if (semProduto) return;
    dialogDetalhesProdutosContratados.value.openDialog(data);
};

const openDialogLiberarUnidade = (record) => dialogLiberarUnidade.value?.openDialog(record);
const openDialogFalhaLiberacao = (errorsList) => dialogFalhaLiberacao.value.openDialog(errorsList);
const openDialogMaisDetalhes = (record) => dialogMaisDetalhes.value.openDialog(record);
const openDialogCancelarVisitaTecnica = (record) => dialogCancelarVisitaTecnica.value?.openDialog(record);
const openDialogGeracaoRelatorios = (record) => dialogRelatoriosLpp.value.openDialog(record, possuiProdutoLppContratado(record));
const openDialogSucessoEnvioRelatorioPorEmail = () => dialogSucessoEnvioRelatorioPorEmail.value.openDialog();
const openDialogEfetivarConclusao = (record) => dialogEfetivarConclusao.value.openDialog(record);

const podeLiberarVisitaTecnica = (record) => {
    const temPermissao = checkPermission('gestaosesmt_gro_unidade:liberar');
    const unidadeContemProdutoLpp = possuiProdutoLppContratado(record);
    const statusConcluido =
        record.statusGro === statusCustomerBranchOptions.value.find((p) => p.label === StatusGroCustomerBranchEnum.CONCLUIDO)?.value;
    const statusNovaUnidade =
        record.statusGro === statusCustomerBranchOptions.value.find((p) => p.label === StatusGroCustomerBranchEnum.NOVA_UNIDADE)?.value;

    return temPermissao && unidadeContemProdutoLpp && (statusConcluido || statusNovaUnidade);
};

const possuiProdutoLppContratado = (record) => {
    const produtoLpp = produtoServicoOptions.value.find((p) => p.label === TipoProdutoServicoEnum.LPP)?.value;
    const contemLpp = record.produtoServicoUnidade.some((p) => p.produtoServico.tipoInventario === produtoLpp);
    return contemLpp;
};

const podeElaborarPgr = (record) => {
    const temPermissao = checkPermission('gestaosesmt_gro_unidade:elaborar');
    const isEmElaboracaoOuValidado = validarStatusEmElaboracaoOuValidado(record.statusGro);
    return temPermissao && isEmElaboracaoOuValidado;
};

const validarStatusEmElaboracaoOuValidado = (status) => {
    return (
        status === statusCustomerBranchOptions.value.find((p) => p.label === StatusGroCustomerBranchEnum.EM_ELABORACAO)?.value ||
        status === statusCustomerBranchOptions.value.find((p) => p.label === StatusGroCustomerBranchEnum.VALIDADO)?.value
    );
};

const getCustomer = async (customerId) => {
    const { data } = await new SesmtService('/customers').findById(customerId);
    customer.value = data;
};

const getStatusCustomerBranchOptions = () => {
    statusCustomerBranchOptions.value = Object.keys(StatusGroCustomerBranchEnum).map(function (type) {
        return {
            label: `${StatusGroCustomerBranchEnum[type]}`,
            value: `${type}`
        };
    });
};

const getProdutoServicoOptions = () => {
    produtoServicoOptions.value = Object.keys(TipoProdutoServicoEnum).map(function (type) {
        return {
            label: `${TipoProdutoServicoEnum[type]}`,
            value: `${type}`
        };
    });
};

const aplicarFiltros = async (removerFiltros = false) => {
    if (removerFiltros) {
        filtrosDoComponenteUnidade.value = {};
        atualizarQueryParamsStore({});
    }

    await load();
};

const atualizarQueryParams = async () => {
    const { customerBranch, status } = filtrosDoComponenteUnidade.value;

    atualizarQueryParamsStore({
        customerBranch: customerBranch ? JSON.stringify(customerBranch.id) : undefined,
        status
    });

    await router.replace({
        query: {
            customerBranch: customerBranch ? JSON.stringify(customerBranch.id) : undefined,
            status
        }
    });
};

const atualizarQueryParamsStore = (params) => store.dispatch('setQueryParamsTelaUnidadesGro', params);

const load = async () => {
    try {
        loading.value = true;
        const { data } = await getClientSesmt().get('/customer/branches', {
            params: {
                limit: recordsPerPage.value,
                page: page.value,
                sort: sort.value,
                filtrosExtras: {
                    ativo: true,
                    customerId: router.currentRoute.value?.params?.id,
                    customerBranchId: filtrosDoComponenteUnidade.value?.customerBranch?.id,
                    status: filtrosDoComponenteUnidade.value?.status
                }
            }
        });

        records.value = data.items;
        totalRecords.value = data.meta?.totalItems;
        totalPages.value = data.meta?.totalPages;

        await getProdutosServicosDasUnidades(records.value);
        setProdutosServicosNasUnidades(records.value, produtosServicosTodasUnidades);

        loading.value = false;
    } catch (error) {
        loading.value = false;
        const message = error?.response?.data?.message;
        alertError('Erro ao listar! ' + message);
    }
};

const getProdutosServicosDasUnidades = async (records) => {
    if (!records.length) return;

    const promises = records.map((p) =>
        getClientSesmt()
            .get(`/produtos-servicos-unidades/cliente/${p.cliente.id}/unidade/${p.id}`)
            .then((response) => ({ id: p.id, produtosServicosUnidade: response.data }))
            .catch((error) => {
                throw error;
            })
    );
    const results = await Promise.all(promises);
    produtosServicosTodasUnidades.value = results;
};

function progressaoStatus(idUnidade) {
    if (!produtosServicosTodasUnidades.value.length) return 0;
    const produtosServicosUnidade = produtosServicosTodasUnidades.value.find((p) => p.id === idUnidade)?.produtosServicosUnidade || [];
    const PROGRESSO_PORCENTAGEM = 100 / produtosServicosUnidade.length;
    const statusConcluido = statusCustomerBranchOptions.value.find((p) => p.label === StatusGroCustomerBranchEnum.CONCLUIDO)?.value;
    let progresso = 0;
    produtosServicosUnidade.forEach((p) => {
        if (p?.statusGro === statusConcluido) {
            progresso += PROGRESSO_PORCENTAGEM;
        }
    });
    return progresso;
}

function getValorConcluido(idUnidade) {
    if (!produtosServicosTodasUnidades.value.length) return 0;
    const produtosServicosUnidade = produtosServicosTodasUnidades.value.find((p) => p.id === idUnidade)?.produtosServicosUnidade || [];
    const statusConcluido = statusCustomerBranchOptions.value.find((p) => p.label === StatusGroCustomerBranchEnum.CONCLUIDO)?.value;
    const valorConcluido = produtosServicosUnidade.filter((p) => p?.statusGro === statusConcluido).length;
    return valorConcluido;
}

function getValorTotal(idUnidade) {
    if (!produtosServicosTodasUnidades.value.length) return 0;
    const produtosServicosUnidade = produtosServicosTodasUnidades.value.find((p) => p.id === idUnidade)?.produtosServicosUnidade || [];
    return produtosServicosUnidade.length;
}

const setProdutosServicosNasUnidades = (records, produtosServicosTodasUnidades) => {
    if (!records.length) return;

    records.forEach((record) => {
        const produtosServicosUnidadeEncontrados = produtosServicosTodasUnidades.value.find((item) => item.id === record.id);
        produtosServicosUnidadeEncontrados
            ? (record.produtoServicoUnidade = produtosServicosUnidadeEncontrados.produtosServicosUnidade)
            : (record.produtoServicoUnidade = []);
    });
};
const init = async () => {
    store.dispatch('setQueryParamsTelaInventarioRiscoGro', {});

    const routeQueryParams = router.currentRoute.value?.query;
    queryParams.value = Object.keys(routeQueryParams).length ? routeQueryParams : store.getters.queryParamsTelaUnidadesGro;
    if (Object.keys(queryParams.value).length) {
        let customerBranch;

        if (queryParams.value?.customerBranch) {
            const { data } = await getClientSesmt().get(`/customer/branches/${queryParams.value?.customerBranch}`);
            customerBranch = data;
        }

        filtrosDoComponenteUnidade.value = {
            customerBranch: customerBranch,
            status: queryParams.value?.status
        };
        await atualizarQueryParams();
    } else {
        filtrosDoComponenteUnidade.value = {
            customerBranch: queryParams.value?.customerBranch,
            status: queryParams.value?.status
        };

        await atualizarQueryParams();
    }

    try {
        const customerId = router.currentRoute.value?.params?.id ? Number(router.currentRoute.value?.params?.id) : 0;
        await getCustomer(customerId);
        getStatusCustomerBranchOptions();
        getProdutoServicoOptions();
        await atualizarQueryParams();
        await load();
    } catch (error) {
        alertError(error, 'Ocorreu um problema');
    }
};

onBeforeMount(init);
</script>
<template>
    <div class="card">
        <HeaderPanel tooltip="Listagem de unidades" :title="'Unidades'" nomeTelaDoManual="customer-branches-gro">
            <template #extraContent>
                <div class="flex flex-row justify-content-center align-items-center gap-2 identificacao-customer">
                    <i class="pi pi-building text-4xl"></i>
                    <div class="flex flex-column">
                        <span class="font-semibold line-height-3 cursor-pointer nome-cliente" v-tooltip.bottom="customer?.name">{{
                            customer?.name
                        }}</span>
                        <span class=""> {{ customer?.code ? `Cód. SOC - ${customer?.code}` : '' }}</span>
                    </div>
                </div>
            </template>
        </HeaderPanel>
        <FiltrosUnidade
            v-model:filtrosExtras="filtrosDoComponenteUnidade"
            :customerId="customer?.id"
            :statusOptions="statusCustomerBranchOptions"
            @loadCustomerBranches="aplicarFiltros"
        />
        <DataTable
            dataKey="id"
            :value="records"
            lazy
            :loading="loading"
            loadingIcon="pi pi-spinner"
            paginator
            :rows="recordsPerPage"
            :totalRecords="totalRecords"
            :rowsPerPageOptions="[10, 50, 100]"
            tableStyle="min-width: 50rem; height: 100%"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Página {currentPage} de {totalPages}"
            :sortField="currentSortField"
            :sortOrder="currentSortOrder"
            removableSort
            @page="onPage"
            @sort="onSort"
        >
            <template #empty> Nenhum registro encontrado.</template>
            <template #loading>
                <AppLoadingWrapper />
            </template>
            <Column
                v-for="col of unidadesColumns"
                :field="col.field"
                :key="col.field"
                :style="col.style"
                :sortable="col.sortable"
                :sortField="col.sortField"
            >
                <template #header>
                    <div class="font-semibold">{{ col.header }}</div>
                </template>

                <template #body="{ data, index }">
                    <template v-if="col.field === 'produtosContratados'">
                        <Button
                            v-tooltip="{ value: 'Nenhum produto contratado.', disabled: !!data?.produtoServicoUnidade?.length }"
                            label="Ver produtos"
                            class="p-button-link"
                            @click="openDialogProdutosContratados(data, !data?.produtoServicoUnidade?.length)"
                        />
                    </template>

                    <template v-else-if="col.field === 'statusGro'">
                        <ProgressoStatus
                            :status="data.statusGro"
                            :progresso="progressaoStatus(data.id)"
                            :valorConcluido="getValorConcluido(data.id)"
                            :valorTotal="getValorTotal(data.id)"
                        />
                    </template>

                    <template v-else-if="col.field === 'acoes'">
                        <Button class="p-button-text p-button-secondary" icon="pi pi-ellipsis-v" @click="toggleMenu($event, index)" />
                        <Menu :ref="(el) => (menu[index] = el)" :model="getActionItems(data)" :popup="true" />
                    </template>

                    <template v-else>
                        <span>{{ data[col.field] }}</span>
                    </template>
                </template>
            </Column>
        </DataTable>
        <DialogDetalhesProdutosContratados ref="dialogDetalhesProdutosContratados" />
        <DialogCancelarVisitaTecnica ref="dialogCancelarVisitaTecnica" @onSuccessCancelamentoLiberacao="load" />
        <DialogFalhaLiberacao ref="dialogFalhaLiberacao" />
        <DialogMaisDetalhes ref="dialogMaisDetalhes" />
        <DialogRelatoriosLpp ref="dialogRelatoriosLpp" @onSuccessEnvioRelatorioPorEmail="openDialogSucessoEnvioRelatorioPorEmail" />
        <DialogSucessoEnvioRelatorioPorEmail ref="dialogSucessoEnvioRelatorioPorEmail" />
        <DialogLiberarElaboracao ref="dialogLiberarParaElaboracao" @onSuccessElaboracao="load" />
        <DialogEfetivarConclusao ref="dialogEfetivarConclusao" @onSuccessLiberarEfetivacao="load" />
        <DialogLiberarUnidade ref="dialogLiberarUnidade" @onSuccessLiberacao="load" @onErrorLiberacao="openDialogFalhaLiberacao" />
    </div>
</template>

<style lang="scss" scoped>
.identificacao-customer {
    padding: 1rem;
    border: solid #ededed 1px;
    border-radius: 4px;
}

.nome-cliente {
    max-width: 25ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
